<template>
    <el-dialog :visible.sync="show" :destroyOnClose="true" @close="close">
        <img v-if="isImgAdvertFileType()" :src="advertUrl" class="image" />
        <video v-else-if="isVideoAdvertFileType()" :src="advertUrl" controls autoplay class="video" />
        <div slot="footer">
            <el-button @click="show = false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import AdvertUtils from 'js/AdvertUtils';

export default {
    name: 'AdvertPreview',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
            advertFileType: -1,
            advertUrl: '',
        };
    },
    watch: {
        visible: {
            handler(n, o) {
                this.show = n;
            },
            immediate: true,
        },
        show: {
            handler(n, o) {
                this.$emit('update:visible', n);
            },
            immediate: true,
        },
    },
    methods: {
        showDialog(advertFileType, advertUrl) {
            this.show = true;
            this.advertFileType = advertFileType;
            this.advertUrl = advertUrl;
        },
        isImgAdvertFileType() {
            return AdvertUtils.isImgAdvertFileType(this.advertFileType);
        },
        isVideoAdvertFileType() {
            return AdvertUtils.isVideoAdvertFileType(this.advertFileType);
        },
        close() {
            Object.assign(this.$data, this.$options.data());
        },
    },
};
</script>
<style scoped>
.image,
.video {
    width: 800px;
    height: 600px;
    display: block;
    margin: 0 auto;
    padding: 0;
}
</style>
